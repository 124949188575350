<template>
  <div v-if="hasClientId" class="w-100" @click="login">
    <slot></slot>
  </div>
</template>

<script>
import {SignInWithApple} from '@capacitor-community/apple-sign-in';
import {appleClientId} from '@/constant/env';

export default {
  data() {
    return {
      hasClientId: !!appleClientId,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.env.isLoading;
    },
  },
  methods: {
    async login() {
      if (this.isLoading) {
        return;
      }
      this.$store.commit('env/setIsLoading', true);
      try {
        const options = {
          clientId: appleClientId,
          redirectURI: `${window.location.origin}`,
          scopes: 'email name',
          state: window.location.href,
          responseMode: 'form_post',
        };
        const appleUser = await SignInWithApple.authorize(options);
        const {identityToken} = appleUser.response;
        if (!identityToken) throw new Error();
        await this.loginUser({identityToken, options: appleUser.response});
        this.$store.commit('env/setIsLoading', false);
        await this.$router.push('/');
      } catch (e) {
        this.$store.commit('env/setIsLoading', false);
        this.$Message.error(this.$t('登入失敗'));
      }
    },
    async loginUser({identityToken, options}) {
      const platform = 'apple';
      await this.$store.dispatch('user/thirdPartyLogin', {
        token: identityToken,
        platform,
        options,
      });
      await this.$store.dispatch('user/loginWithJwt');
      try {
        this.$JPush.updateTags();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
