<template>
  <div v-if="hasGoogleClientId" class="w-100" @click="login">
    <slot></slot>
  </div>
</template>

<script>
import {GoogleAuth} from '@codetrix-studio/capacitor-google-auth';
import {googleClientId} from '@/constant/env';

export default {
  data() {
    return {
      hasGoogleClientId: !!googleClientId,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.env.isLoading;
    },
  },
  mounted() {
    if (this.hasGoogleClientId) {
      GoogleAuth.initialize({clientId: googleClientId});
    }
  },
  methods: {
    async login() {
      if (this.isLoading) {
        return;
      }
      this.$store.commit('env/setIsLoading', true);
      try {
        const googleUser = await GoogleAuth.signIn();
        const idToken = googleUser?.authentication?.idToken;
        if (!idToken) throw new Error();
        await this.loginUser(idToken);
        this.$store.commit('env/setIsLoading', false);
        await this.$router.push('/');
      } catch (e) {
        this.$store.commit('env/setIsLoading', false);
        this.$Message.error(this.$t('登入失敗'));
      }
    },
    async loginUser(token) {
      const platform = 'google';
      await this.$store.dispatch('user/thirdPartyLogin', {token, platform});
      await this.$store.dispatch('user/loginWithJwt');
      try {
        this.$JPush.updateTags();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
