import AppPlugin from '../base/appPlugin';
import {lineClientId} from '@/constant/env';

class Line extends AppPlugin {
  constructor() {
    super('Line');
    this.baseURI = 'https://access.line.me/oauth2/v2.1/authorize';
    this.clientId = lineClientId;
    this.redirectUri = `${window.location.origin}/lineAuth.html`;
    this.state = parseInt(Math.random() * 100000).toString();
    this.hasListener = false;
    this.windowWidth = 700;
    this.windowHeight = 700;
    this.handler = null;
  }
  webSignIn() {
    return new Promise((resolve, reject) => {
      const win = window.open(this.apiUrl, 'LineAuthentication', this.specs);
      let timer = setInterval(() => {
        if (win.closed && timer) {
          clearInterval(timer);
          timer = null;
          this.removeEvent();
          return reject(new Error('close the login window'));
        }
      }, 300);
      this.handler = (e) => {
        if (e.origin !== window.location.origin) return;
        try {
          const url = new URL(e.data);
          const searchParams = url.searchParams;
          const result = {redirectUri: this.redirectUri};
          for (const entry of searchParams.entries()) {
            result[entry[0]] = entry[1];
          }
          if (result.state !== this.state) {
            throw new Error('State Error');
          }
          this.removeEvent();
          clearInterval(timer);
          timer = null;
          win.close();
          resolve(result);
        } catch (error) {
          this.removeEvent();
          clearInterval(timer);
          timer = null;
          win.close();
          reject(error);
        }
      };
      this.addEvent();
    });
  }

  get windowLeft() {
    const browserWidth =
      window.innerWidth || document.documentElement.clientWidth || screen.width;
    return browserWidth / 2 - this.windowWidth / 2 + window.screenLeft;
  }
  get windowTop() {
    const browserHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      screen.height;
    return browserHeight / 2 - this.windowHeight / 2 + window.screenLeft;
  }
  get specs() {
    return `fullscreen=no,resizable=no,location=no,menubar=no,width=${this.windowWidth},height=${this.windowHeight},left=${this.windowLeft},top=${this.windowTop}`;
  }
  get apiUrl() {
    return `${this.baseURI}?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUri}&state=${this.state}&scope=profile%20openid%20email`;
  }

  removeEvent() {
    if (!this.hasListener && this.handler) return;
    window.removeEventListener('message', this.handler, false);
    this.handler = null;
    this.hasListener = false;
  }
  addEvent() {
    if (this.hasListener || !this.handler) return;
    window.addEventListener('message', this.handler, false);
    this.hasListener = true;
  }
}

export default new Line();
