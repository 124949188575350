<template>
  <div v-if="hasClientId" class="w-100" size="md" @click="login">
    <slot></slot>
  </div>
</template>

<script>
import line from '@/lib/line';
import {lineClientId} from '@/constant/env';

export default {
  data() {
    return {
      hasClientId: !!lineClientId,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.env.isLoading;
    },
  },
  methods: {
    async login() {
      if (this.isLoading) {
        return;
      }
      this.$store.commit('env/setIsLoading', true);
      try {
        const lineData = await line.webSignIn();
        const identityToken = lineData.code;
        if (!identityToken) throw new Error();
        await this.loginUser({identityToken, options: lineData});
        this.$store.commit('env/setIsLoading', false);
        await this.$router.push('/');
      } catch (e) {
        this.$store.commit('env/setIsLoading', false);
        this.$Message.error(this.$t('登入失敗'));
      }
    },
    async loginUser({identityToken, options}) {
      const platform = 'line';
      await this.$store.dispatch('user/thirdPartyLogin', {
        token: identityToken,
        platform,
        options,
      });
      await this.$store.dispatch('user/loginWithJwt');
      try {
        this.$JPush.updateTags();
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
